form .error-message {
    padding: 10px;
    border-radius: 3px;
    font-weight: 600;
    font-size: .8em;
    background-color: red;
    opacity: .8;
}
.input-container {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 90%;
}
.input-container .input-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.input-container .input-header label {
    font-size: .8rem;
    opacity: 0.7;
}
.input-container input {
    padding: .5rem;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
}
.input-container input:invalid[focused='true'] {
    border: 1px solid red;
}
.input-container input:invalid[focused='true'] ~ span {
    visibility: visible;
}
.input-container > span {
    min-height: .8em;
    color: red;
    visibility: hidden;
    font-size: .8em;  
}
.tooltip {
    opacity: 1 !important;
    z-index: 10000;
}
.tooltip .description {
   font-size: .8rem;
   white-space: pre-line;
}
.tooltip .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.tooltip .images .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .7rem;
}
