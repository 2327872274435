footer {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 5vh;
    max-height: 5vh;
    background-color: #4c93e6;
    align-items: center;
    justify-content: center;
    margin-top: auto;
}
@media (max-width: 768px) {
    footer {
        display: none !important; /* Hide contact info in footer if on mobile device */
    }
}

footer nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    gap: 40px;
}
footer nav h3 {
    flex: 1;
    color: white;
    font-weight: bold;
    margin: 0;
}
footer nav a {
    flex: 2;
    display: flex;
    gap: 10px;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
footer nav a:link {
    text-decoration: none;
}
footer nav a:visited {
    text-decoration: none;
}
footer nav a:hover {
    text-decoration: none;
    font-weight: bolder;
}

  /* Media query for screens with a maximum width of 767 pixels (typical for mobile devices) */
  @media (max-width: 767px) {
    footer {
        font-size: 12px !important;
    }

    footer nav {
        gap: 12px !important;
    }

    footer nav a:link {
        font-size: 12px !important;
    }
  }