.mapbox-popup {
  /* Anpassa popup-stilar här */
  background-color: #fff;
  border: 1px solid #e2f3fe;
  padding: 10px;
  border-radius: 5px;
}

.mapbox-popup-item {
  width: fit-content;
  block-size: fit-content;
}

.mapbox-popup h3 {
  color: #1e73be;
}

.mapbox-popup p {
  margin: 2px 0;
}

.mapbox-popup p:last-child {
  margin-bottom: 0;
}

.icon-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Justera ikonerna till vänster i varje rad */
  /* gap: 5px; Lägg till ett mellanrum mellan varje rad */
  max-width: 200px;
  margin-right: 10px;
}

.icon-list p {
  margin: 0;
  align-items:start;
}

/* .icon-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

.icon-list div {
  display: flex;
  flex-direction: column;
}


.icon-list p {
  display: flex;
  align-items: center;
}

.icon-list i {
  margin-right: 10px;
}

.mapbox-popup {
  width: 800px; /* Increase max-width to accommodate both popups side by side */
  font-family: 'proxima-nova', 'Arial', sans-serif;
}

.mapboxgl-popup-content {
  width: 340px;
  box-shadow: none !important;
}

.popup-container {
  display: flex;
}

.icon-list {
  flex: 1;
}

.popup-container {
  display: flex;
}

.popup {
  margin: 10px; /* Add margin to separate popups */
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.connector-line {
  background-color: #000;
  width: 1px;
  height: 50px; /* Adjust the height of the connector line */
  margin: 0 5px; /* Adjust the margin for spacing */
}
