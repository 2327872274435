.roof-map-container {
    padding: 0px calc(20px + 3.5vw);
    width: 100%;
}

.roof-map {
    /* flex: 1; Make the map container take up the available space */
    height: 70vh;
    /* Set the height of the map to 100% of its container by default */
}

/* Media query for screens with a maximum width of 767 pixels (typical for mobile devices) */
@media (max-width: 767px) {
    .roof-map {
        height: 78vh;
        /* Set the height of the map to 100% of the viewport height on mobile devices */
    }

    .roof-map-container {
        padding: 0px;
    }
}

.layer-toggle-button {
    --inactive-color: #BBBBBB;
    --active-color: #4c93e6;
    font: inherit;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 0;
    border: 2px solid var(--inactive-color);
    background: white;
}

.layer-toggle-button:not(:first-child) {
    border-left: none;   
}

.layer-toggle-button.layer-active {
    border-color: var(--active-color);
    color: var(--active-color);
    text-shadow: 0px 0px 1px var(--active-color);
    background-color: #F2F8FC;
}

.layer-toggle-button.layer-active:not(:first-child) {
    border-left: 2px solid var(--active-color);
    margin-left: -2px;
}

/* .layer-toggle-button:first-child {
    border-radius: 10px 0 0 10px;
}

.layer-toggle-button:last-child {
    border-radius: 0 10px 10px 0;
} */