.open-modal-btn {
  border: none;
  padding: .5rem;
  color: #ffffff;
  background-color: #4c93e6;;
  font-weight: 600;
  cursor: pointer;
  border-radius: 3px;
  width: 100px;
  height: 40px;
}
.export-button {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}
.menu-row {
  position: relative;
  width: 80%;
  min-height: 10vh;
  max-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.menu-row nav {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.menu-row nav ul {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 20%;
  margin: 0;
  padding: 0;
}
.menu-row nav ul li {
  display: flex;
  list-style: none;
}
.menu-row nav ul li a {
  text-decoration: none;
  white-space: nowrap;
  color: #aaa;
  font-weight: 600;
  width: 100%;
}
.menu-row li .active {
  color: #000000;
}
