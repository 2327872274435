.homepageContainer {
  display: flex;
  flex-direction: column;
  /* Ställ in riktningen till kolumn (vertikal) */
  height: 100vh;
}

/* Media query for screens with a maximum width of 767 pixels (typical for mobile devices) */
@media (max-width: 767px) {
  .homepageDashboard {
    display: none !important;
  }

  .footer-arrow {
    display: flex !important
  }
}

.homepageDashboard {
  display: flex;
  flex-direction: row;
}

.homepageDashboardElements {
  width: 40%;
  float: left;
  padding: 20px;
  text-align: justify;
  border: 1px;
  margin-right: 10px;
  /* Justera storleken på marginalen efter behov */
  margin-left: 3.5vw;
  display: inline-block;
  /* För att hindra dem från att staplas vertikalt */
  font-family: 'proxima-nova',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4c93e6;

}

.homepageDashboardElementsLeft {
  width: 30%;
  float: left;
  text-align: right;
  padding: 20px;
  border: 1px;
  margin-right: 10px;
  /* Justera storleken på marginalen efter behov */
  display: inline-block;
  /* För att hindra dem från att staplas vertikalt */
  font-family: 'proxima-nova',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4c93e6;
  margin-top: 2%;
}

/* Lägg till dessa stilar i din CSS-fil eller i <style> taggar i head-sektionen av din HTML */

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Bakgrundsfärg för bakomliggande overlay */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

.modal p {
  margin-bottom: 10px;
}

.modal button {
  padding: 10px;
  background-color: #4c93e6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Lägg till dessa stilar om du vill animera modalen */
.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
  transform: scale(1);
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.modal-button-container {
  margin-top: 20px;
  /* Justera vid behov för önskat avstånd från övrigt innehåll */
  display: flex;
  justify-content: flex-end;

}

.footer-arrow {
  height: 5vw;
  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #4c93e6;
  font-weight: bold;
  margin-top: 12vw;
  margin-bottom: -5vw;
}

.footer-arrow h4 {
  margin-top: 0vw;
}

.hidden {
  display: none !important;
}

#info {
  width: 80%;
  float: left;
  padding: 20px;
  text-align: left;
  border: 1px;
  margin-right: 10px;
  /* Justera storleken på marginalen efter behov */
  margin-left: 10px;
  font-family: 'proxima-nova',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4c93e6;
}

#info h3 {
  text-align: left !important;
}

#info h4 {
  text-align: center;
  margin-top: 5px;
}

#info i {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-number {
  text-align: left !important;
  margin-top: 50px;
}

.image {
  margin-left: 5vw;
}