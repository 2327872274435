.admin-nav {
}
.admin-nav ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px
}
.admin-nav ul li {
    list-style: none;
    font-size: 1.2rem;
}
.admin-nav ul li a {
    text-decoration: none;
    font-weight: 600;
    color: #b5c1e4;
}
.admin-nav ul li .active {
    color: #4c93e6;
}
.dashboard .kpis{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5vw;
    width: 80vw;
}
.kpi-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    background-color: #4c93e6;
    height: 90px;
    width: 160px;
    padding: 10px;
    border-radius: 5px;
}
.kpi-container span {
    color: #ffffff;
    font-weight: 600;
}
.kpi-container span:nth-child(1) {
    font-size: 1.8rem;
}
.kpi-container span:nth-child(2) {
    font-size: .9rem;
}
.dashboard {
    margin-bottom: 2vh;
}
.open-modal-btn {
    border: none;
    padding: .5rem;
    color: #ffffff;
    background-color: #4c93e6;
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    width: 100px;
    height: 40px;
  }
