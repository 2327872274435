header {
    position: relative;
    width: 100%;
    min-height: 10vh;
    max-height: 10vh;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}
header nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-left: 2.5vw;
}
header nav li {
    list-style: none;
}
header nav li a {
    color: #b5c1e4;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 600;
}
header nav li .active {
    color: #4c93e6;
}
header .logo {
    display: flex;
    max-width: 50px;
    min-width: 50px;
}
header .right-section {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-around;
    margin-right: 2rem;
}
header .dropdown {
    display: flex;
    flex-direction: column;
}
header .dropdown .expand-btn {
    width: 25px;
    height: 25px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
}
header .dropdown .expand-btn:hover {
    background: rgba(0, 0, 0, 0.1);
}
header .dropdown .expand-btn:active {
    transform: scale(.9);
}
header .dropdown .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
header .dropdown .user-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
header .dropdown span {
    font-size: 1rem;
    font-weight: 600;
}
header .dropdown span:nth-child(2) {
    font-size: .8rem;
    opacity: .6;
}
header .dropdown .menu {
    position: absolute;
    top: 10vh;
    right: 0;
    display: flex;
    gap: 25px;
    flex-direction: column;
    align-items: center;
    min-width: fit-content;
    z-index: 10000;
    background-color: #e2f3fe;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
header .dropdown .menu .ReactFlagsSelect-module_selectBtn__19wW7 {
    padding-top: 0;
    padding-bottom: 0;
}
header .dropdown .auth-btn {
    background-color: #4c93e6;
    color: #ffffff;
    border: none;
    padding: 10px;
    width: 120px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
}
