.ReactModal__Content {
    background-color: #e2f3fe;
}
.ReactModal__Content form {
    display: flex;
    height: 100%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    background-color: #e2f3fe;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 10000;
}

.ReactModal__Content.ReactModal__Content--after-open {
    overflow: visible !important;
    background-color: #e2f3fe;
}

.ReactModal__Content section {    
    display: flex;
    background-color: #e2f3fe;
}
.ReactModal__Content .modal-header {
    padding-left: 20px;
    border-bottom: 1px solid rgb(204, 204, 204);
    background-color: #e2f3fe;
}
.ReactModal__Content .modal-body {
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    background-color: #e2f3fe;
}
.ReactModal__Content .modal-body .owner-title{
   display: flex;
   align-self: flex-start;
   margin-left: 30px;
   margin-bottom: 20px;
}
#delete .modal-header {
    border: none;
}
#delete .modal-footer {
    border: none;
}
#flag .modal-header {
    border: none;
}
#flag .modal-footer {
    border: none;
}
.ReactModal__Content .modal-footer {
    min-height: 10%;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    border-top: 1px solid rgb(204, 204, 204);
}
.ReactModal__Content button {
    display: flex;
    align-items: center;
    border: none;
    font-weight: 600;
    cursor: pointer;
    height: 2rem;
}
.ReactModal__Content button[type='submit'] {
    background-color: #4c93e6;;
    color: #ffffff;
    padding: 1rem;
    border-radius: 3px;
}
.ReactModal__Content button[type='reset'] {
    background-color: transparent;
    color: #000000;
    font-size: .8em;
    width: 5rem;
}
