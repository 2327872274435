.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    gap: 10%;
    width: 30vw;
    height: 50vh;
    border: 2px solid #E8E8E8;
    background-color: white;
}
.login-container h1 {
    position: relative;
    bottom: 10%;
    font-size: 1.9rem;
    opacity: .8;
}
.login-container .info {
    max-width: 80%;
    font-size: .8rem;
    opacity: .6;
}
