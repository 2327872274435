.table-menu {
    display: flex;
    flex-direction: row;
    max-width: 80%;
    min-width: 80%;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
}
.table-menu .table-search {
    border: 1px solid rgb(204, 204, 204);
    padding: .5rem;
    height: fit-content;
    width: 250px;
    border-radius: 3px;
    padding-left: 20px;
    outline: none;
}
.table-menu .buttons {
    display: flex;
    flex-direction: row;
    width: 50%;
    gap: 20px;
}
.table-menu .edit-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.filters-section {
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 1rem;
    flex-wrap: wrap;
    min-width: 80%;
    max-width: 80%;
    overflow: auto;
    max-height: 0px;
    transition-property: all;
    transition-duration: .2s;
}
.filters-section.expanded {
    overflow: visible;
    max-height: fit-content;
    padding: 1rem;
}
.filter-container { 
    position: relative;
    display: flex;
    overflow: visible;
    gap: .8rem;
    flex-direction: column;
}
.filter-container span {
    font-size: .8rem;
}
.filter-btn {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.range {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    min-height: 22px;
    max-height: 22px;
    width: 70px;
    padding-left: 5px;
}
table {
    display: flex;
    flex-direction: column;
    border-spacing: 0;
    width: 80vw;
    max-width: 80vw;
    min-width: 80vw;
    flex-grow: 1;
    font-size: .8rem;
    overflow-y: auto;
}
table thead {
    position: sticky;
    top: 0;
    min-width: 100%;
    background-color: #ffffff;
}
table thead th {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 0;
    height: 60px;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    color: #595959;
    background-color: #ffffff;
}
table tbody tr {
    display: flex;
    width: 100%;
}
table tbody tr td {
    border-bottom: 1px solid lightgrey;
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: left;
    height: 60px;
    white-space: nowrap;
    overflow: hidden;
    transition-property: background-color;
    transition-duration: .3s;
    padding-right: 1rem;
}
table tbody tr .expand-btn {
    transition-property: transform;
    transition-duration: .3s;
    border-radius: 50%;
    z-index: -1;
}
table tbody tr .expand-btn.expanded {
    transform: rotate(90deg);
    z-index: -1;
}
table tbody tr .expand-btn-container {
    display: flex;
    border-radius: 60%;
}
table tbody tr .expand-btn-container:hover {
    background: rgba(0, 0, 0, 0.1);
}
table tbody tr td.expanded {
    border: none;
}
table tbody tr td input {
    border: 0px;
}
table tbody .sub-row {
    height: 0px;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
    transition-property: height;
    transition-duration: .3s;
}
table tbody .sub-row.expanded {
    height: 200px;
    border-bottom: 1px solid rgb(204, 204, 204);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    min-width: 100%;
    flex: 1;
}
table tbody .sub-row .expanded-data {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 90%;
    max-width: 95%;
    padding: 0;
    flex: 1;
    border: none;
    gap: 1rem;
}
table tbody .sub-row .expanded-data .title {
    font-size: .9rem;
    color: lightslategray;
}
table tbody .sub-row .expanded-data .user-section {
    display: flex;
    gap: 1rem;
    width: 25%;
    flex-direction: column;
}
table tbody .sub-row .expanded-data .user-section .containers {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}
table tbody .sub-row .expanded-data .owner-section {
    display: flex;
    gap: 1rem;
    min-width: 20%;
    max-width: 25%;
    flex-direction: column;
}
table tbody .sub-row .expanded-data .owner-section .containers {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}
table tbody .sub-row .expanded-data .roof-section {
    display: flex;
    flex: 1;
    flex-shrink: 2;
    max-width: 50%;
    max-height: 70%;
    flex-direction: column;
    gap: 1rem;
}
table tbody .sub-row .expanded-data .roof-section .containers {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .8rem;
    min-height: 100px;
    max-height: 100px;
    flex-wrap: wrap;
}
table tbody .sub-row .expanded-data .container {
    display: flex;
    flex-direction: row;
    min-height: fit-content;
    font-size: .8rem;
    min-width: 0px;
}
table tbody .sub-row .expanded-data .container span:nth-child(1) {
    display: flex;
    font-weight: 600;
    color: #595959;
    min-width: 110px;
}
table tbody .sub-row .expanded-data .container span:nth-child(2) {
    display: flex;
    flex-direction: row;
    font-weight: 400;
    flex-wrap: wrap;
    white-space: pre-wrap;
    word-break: break-all;
    min-width: 0px;
    flex: 1;
    max-width: 200px;  
}
table tbody .sub-row .expanded-data .user-section .container span:nth-child(1) {
    min-width: 60px;
}
table tbody .sub-row .expanded-data .owner-section .container span:nth-child(1) {
    min-width: 130px;
}
table tbody .sub-row .expanded-data .buttons-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: fit-content;
    height: 100%;
    align-items: flex-end;
    gap: 20px;
}
table tbody .sub-row .expanded-data .buttons-section button {
    display: flex;
    align-items: center;
    justify-content : center;
    background: rgba(0, 0, 0, 0.1);
    padding: 0px 10px;
    min-width: 30px;
    height: 30px;
    border-radius: 15px;
    border: none;
    cursor: pointer;
}
table tbody .sub-row .expanded-data .buttons-section #flag-btn {
    background-color: #F2F2F2;
    cursor: default;
}
.table-footer {
    display: flex;
    width: 80vw;
    padding: 1rem;
}
.table-pagination {
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
}
.table-pagination span {
    font-size: .9rem;
    font-weight: 600;
    opacity: .7;
}
.table-pagination .buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.table-pagination button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: fit-content;
    padding: 0;
}
.table-pagination button:disabled {
    opacity: .5;
    cursor: default;
}

@media screen and (max-width: 1400px) {
    table tbody .sub-row .expanded-data .container span:nth-child(2) {
        max-width: 160px;
    }
}
@media screen and (max-width: 1300px) {
    table tbody .sub-row .expanded-data .container span:nth-child(2) {
        max-width: 120px;
    }
}
@media screen and (max-width: 1200px) {
    table tbody .sub-row.expanded {
        height: 300px;
        width: auto;
    }
    table tbody .sub-row .expanded-data .roof-section .containers {
        flex-wrap: nowrap;
    }
}
@media screen and (min-width: 2000px) {
    table tbody .sub-row .expanded-data .container span:nth-child(2) {
        max-width: 300px;
    }
}
@media screen and (min-width: 2500px) {
    table tbody .sub-row .expanded-data .container span:nth-child(2) {
        max-width: 450px;
    }
}
